<template>
  <div id="home">
    <NavBar :currentRoute="'ScheduleWorker'"/>

    <div v-if="isCurrentUserAdmin" class="container-spacing">
      <ScheduleAdmin/>
    </div>
    <div v-else class="container-spacing">
      <ScheduleWorker/>
    </div>

    <About/>
  </div>
</template>

<script>
import About from "@/components/About";
import NavBar from "@/components/NavBar";
import ScheduleWorker from "@/components/schedule/ScheduleWorker";
import ScheduleAdmin from "@/components/schedule/ScheduleAdmin";
import UserService from "@/service/UserService";

export default {
  name: 'ScheduleView',
  components: {
    NavBar,
    About,
    ScheduleWorker,
    ScheduleAdmin
  },
  computed: {
    isCurrentUserAdmin() {
      return UserService.isCurrentUserAdmin();
    }
  },

}
</script>

<style scoped>
.container-spacing {
  margin-bottom: 65px;
}
</style>