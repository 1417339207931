<template>
  <v-container>

    <v-row align="center" justify="center">
      <!-- error happened -->
      <v-row align="center" justify="center" v-if="errorMessage">
        <v-col cols="5">
          <v-alert type="error" dismissible>{{ errorMessage }}</v-alert>
        </v-col>
      </v-row>

      <!--show waiting schedules if any-->
      <v-row justify="center" v-if="schedules.length > 0" class="ma-6">
        <v-col cols="10" xs="12" md="10">
          <p class="text-center" id="title">Your schedule</p>
        </v-col>
        <v-col cols="10" xs="12" md="6" v-for="s in schedules" :key="s.id">
          <ScheduleWorkerCard :schedule="s" :key="s.id"/>
        </v-col>
      </v-row>
      <!--else show button to set schedule-->
      <v-row v-else-if="!showOverlay" class="ma-10">
        <v-btn outlined @click="openScheduleDialog">
          <v-icon left>
            mdi-format-list-group-plus
          </v-icon>
          Set you schedule
        </v-btn>
      </v-row>

    </v-row>

    <!--dialog to create schedule for current worker-->
    <v-dialog v-model="showScheduleDialog" width="600px" persistent @input="v => v || hideScheduleDialog">
      <NewScheduleWorkerDialog @onClose="hideScheduleDialog"/>
    </v-dialog>

    <v-overlay :value="showOverlay" color="#B4B4B4">
      <v-progress-circular indeterminate size="50" color="amber"/>
    </v-overlay>
  </v-container>
</template>

<script>
import ScheduleService from "@/service/ScheduleService";
import NewScheduleWorkerDialog from "@/components/schedule/dialog/NewScheduleWorkerDialog";
import ScheduleWorkerCard from "@/components/schedule/ScheduleWorkerCard";
import {BackendError} from "@/exceptions/BackendError";

export default {
  name: "ScheduleWorker",
  components: {
    NewScheduleWorkerDialog,
    ScheduleWorkerCard
  },

  async created() {
    await this.getWorkerCurrentSchedules();
  },

  data() {
    return {
      showOverlay: true,
      showScheduleDialog: false,
      schedules: [],
      errorMessage: '',
    }
  },

  methods: {

    async getWorkerCurrentSchedules() {
      this.showOverlay = true;
      try {
        this.schedules = await ScheduleService.getMyScheduleToday();
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        this.errorMessage = "Problem while loading schedules!";
      } finally {
        this.showOverlay = false;
      }
    },

    openScheduleDialog() {
      this.showScheduleDialog = true;
    },

    hideScheduleDialog(arg) {
      if (arg != null && arg.schedule !== undefined) {
        this.schedules.push(arg.schedule);
      }
      this.showScheduleDialog = false;
    }

  },
}
</script>

<style scoped>
#title {
  font-size: 2.3rem;
  color: #4e4747;
}
</style>