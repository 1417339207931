<template>
  <v-container id="coin-selector">
    <!-- error happened -->
    <v-row align="center" justify="center" v-if="errorMessage.length > 0">
      <v-col cols="5">
        <v-alert type="error" dismissible>{{ errorMessage }}</v-alert>
      </v-col>
    </v-row>

    <!--workers without a schedule or schedule request-->
    <v-row justify="center">
      <v-list max-width="1000px" min-width="420px" v-auto-animate="{ duration: 600 }">
        <v-list-item v-for="worker in workers" :key="worker.id" class="worker-item" ripple
                     @click="openScheduleCreationDialog(worker)">
          <v-list-item-avatar>
            <v-icon color="orange darken-3" class="mr-2" dark>
              mdi-account-hard-hat-outline
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-text="worker.username"/>
          <v-list-item-action>
            <v-btn outlined small @click="openScheduleCreationDialog(worker)" color="grey darken-3">
              <v-icon left color="orange darken-3">
                mdi-bookmark-plus-outline
              </v-icon>
              Create Schedule
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-row>

    <!--dialog to create schedule for selected worker-->
    <v-dialog v-model="scheduleCreationDialog" width="600px" persistent @input="v => v || hideScheduleCreationDialog">
      <NewScheduleAdminDialog :worker="selectedWorker" :key="selectedWorker.id"
                              @closeDialog="hideScheduleCreationDialog"/>
    </v-dialog>

  </v-container>
</template>

<script>
import UserService from "@/service/UserService";
import NewScheduleAdminDialog from "@/components/schedule/dialog/NewScheduleAdminDialog";

export default {
  name: "WorkersWithoutScheduleComponent",

  components: {
    NewScheduleAdminDialog
  },

  data() {
    return {
      workers: [],
      showOverlay: false,
      scheduleCreationDialog: false,
      selectedWorker: {},
      errorMessage: '',
      amountRule: [val => (val || '').length > 0 || 'Field can\'t be empty'],
    }
  },

  created() {
    this.getWorkersWithoutSchedule()
  },

  methods: {
    async getWorkersWithoutSchedule() {
      try {
        this.workers = await UserService.getWorkersWithNoSchedules();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.showOverlay = false;
      }
    },

    openScheduleCreationDialog(worker) {
      this.selectedWorker = worker;
      this.scheduleCreationDialog = true;
    },

    hideScheduleCreationDialog(arg) {
      if (arg != null && arg.workerId !== undefined) {
        const index = this.workers.findIndex(prop => prop.id === arg.workerId);
        this.workers.splice(index, 1);
      }
      this.selectedWorker = {};
      this.scheduleCreationDialog = false;
    },
  }
}

</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}

.v-data-table__wrapper > table > tbody > tr:hover {
  background: #fbd7a1 !important;
}

.worker-item {
  border: 1px solid transparent;
}

.worker-item:hover {
  background: #B3E5FC !important;
  border: 1px solid grey;
  border-radius: 6px;
}
</style>