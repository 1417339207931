<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">Confirm worker schedule</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row dense>
          <v-col cols="8" offset="2">
            <span class="text-h6">Worker: {{schedule.worker.username}}</span>
          </v-col>
        </v-row>

        <v-row class="mt-n3">
          <v-col cols="8" offset="2">
            <span class="text-h6">Time: {{timeStart}} - {{timeEnd}}</span>
          </v-col>
        </v-row>

        <v-row class="justify-center mt-n3" v-if="schedule.comment">
          <v-col cols="12">
            <v-textarea name="input-7-4" label="Note" v-model="schedule.comment" rows="4"
                        background-color="amber lighten-4" class="mt-n3"></v-textarea>
          </v-col>
        </v-row>

        <v-col cols="12" v-if="errorMessage">
          <v-alert text type="error">{{ errorMessage }}</v-alert>
        </v-col>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-center mt-n6">
      <v-btn color="grey darken-1" class="mb-5 mt-2" outlined style="min-width: 130px" @click="closeDialog">Close</v-btn>
      <v-btn color="green darken-1" class="mb-5 mt-2 ml-8 px-8" outlined @click="confirm">Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ScheduleService from "@/service/ScheduleService";

export default {
  name: "ConfirmScheduleDialog",

  props: {
    schedule: {
      type: Object,
      required: true
    }
  },

  computed: {
    timeStart() {
      return this.schedule.startedAt.slice(11, 16);
    },
    timeEnd() {
      return this.schedule.endedAt.slice(11, 16);
    }
  },

  data() {
    return {
      errorMessage: null
    }
  },

  methods: {
    closeDialog(arg) {
      this.updateClientErrorMessage = '';
      this.$emit('onClose', arg);
    },

    async confirm() {
      this.showOverlay = true;
      try {
        await ScheduleService.verify(this.schedule.id);
        this.closeDialog();
        this.$emit('onClose', {"scheduleId": this.schedule.id});
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.showOverlay = false;
      }
    }
  }

}
</script>
