<template>
  <v-card flat>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <p class="text-center" id="title">Today's schedules</p>
        </v-col>
        <v-btn-toggle v-model="toggle_exclusive" rounded dense dark background-color="grey">
          <v-btn class="px-12" @click="toggleMode('Waiting')">Waiting confirmation</v-btn>
          <v-btn class="px-12" @click="toggleMode('New')">Create schedule</v-btn>
        </v-btn-toggle>
      </v-row>
      <v-row align="center" justify="center" v-if="receiptMode === 'Waiting'">
          <SchedulesWaitingComponent/>
      </v-row>
      <v-row align="center" justify="center" v-if="receiptMode === 'New'">
        <v-col cols="12">
          <WorkersWithoutScheduleComponent/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SchedulesWaitingComponent from "@/components/schedule/SchedulesWaiting";
import WorkersWithoutScheduleComponent from "@/components/schedule/WorkersWithoutSchedule";

export default {
  name: "ScheduleAdmin",
  components: {SchedulesWaitingComponent, WorkersWithoutScheduleComponent},

  data() {
    return {
      toggle_exclusive: undefined,
      receiptMode: '',
      coinList: [],
      errorMessage: '',
    }
  },

  methods: {
    toggleMode(mode) {
      if (mode !== this.receiptMode) {
        this.receiptMode = mode;
      }
    },
  },
}
</script>

<style scoped>
#title {
  font-size: 2.3rem;
  color: #4e4747;
}

</style>