<template>
  <v-card flat class="py-2" max-width="500px">
    <v-card-text class="mx-n6">
      <v-row>
        <v-col cols="1" offset="2">
          <v-icon color="orange darken-3">
            mdi-account-hard-hat-outline
          </v-icon>
        </v-col>
        <v-col cols="8">
          <span class="text-h6">{{ schedule.worker.username }}</span>
        </v-col>
      </v-row>
      <v-row class="mt-n3">
        <v-col cols="1" offset="2">
          <v-icon color="orange darken-3">
            mdi-clipboard-text-clock-outline
          </v-icon>
        </v-col>
        <v-col cols="8">
          <span class="text-h6">{{ timeStart }} - {{ timeEnd }}</span>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" v-if="schedule.comment.length > 0" class="mb-n10">
        <v-col cols="10" offset="1">
          <v-textarea v-model="schedule.comment" auto-grow rows="2" outlined
                      color="orange orange-darken-4" readonly/>
        </v-col>
      </v-row>

      <!--if admin: if status waiting: show buttons to accept or cancel-->
      <v-row align="center" justify="center" v-if="isCurrentUserAdmin && schedule.status === 0">
        <v-col cols="4" offset="1">
          <v-btn outlined color="red lighten-2" @click="showCancelDialog = true">
            <v-icon>
              mdi-close
            </v-icon>
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="4" offset="1">
          <v-btn outlined color="green darken-1" @click="showConfirmDialog = true">
            <v-icon>
              mdi-check
            </v-icon>
            Confirm
          </v-btn>
        </v-col>
      </v-row>

      <!--else worker: show schedule status-->
      <v-row v-else class="mt-n6">
        <v-spacer></v-spacer>
        <v-chip class="my-2 mr-8" :color="scheduleStatus.color" label>
          <v-icon left>
            {{ scheduleStatus.icon }}
          </v-icon>
          {{ scheduleStatus.name }}
        </v-chip>
      </v-row>
    </v-card-text>

    <v-dialog v-model="showConfirmDialog" width="400px" persistent @input="v => v || closeConfirmDialog">
      <ConfirmScheduleDialog @onClose="closeConfirmDialog" :schedule="schedule" :key="schedule.id"/>
    </v-dialog>

    <v-dialog v-model="showCancelDialog" width="400px" persistent @input="v => v || closeCancelDialog">
      <CancelScheduleDialog @onClose="closeCancelDialog" :schedule="schedule" :key="schedule.id"/>
    </v-dialog>
  </v-card>
</template>

<script>
import UserService from "@/service/UserService";
import ConfirmScheduleDialog from "@/components/schedule/dialog/ConfirmScheduleDialog"
import CancelScheduleDialog from "@/components/schedule/dialog/CancelScheduleDialog";

export default {
  name: "ScheduleWorkerCard",
  props: {
    schedule: {
      type: Object,
      required: true
    }
  },

  components: {CancelScheduleDialog, ConfirmScheduleDialog},

  computed: {
    isCurrentUserAdmin() {
      return UserService.isCurrentUserAdmin();
    },
    timeStart() {
      return this.schedule.startedAt.slice(11, 16);
    },
    timeEnd() {
      return this.schedule.endedAt.slice(11, 16);
    },
    scheduleStatus() {
      if (this.schedule.status === 0) {
        return {
          "name": 'Waiting confirmation',
          "icon": "mdi-clock-time-two-outline",
          "color": "yellow lighten-3",
        };
      } else if (this.schedule.status === 1) {
        return {
          "name": 'Confirmed',
          "icon": "mdi-check",
          "color": "green lighten-3",
        };
      } else {
        return {
          "name": "Denied",
          "icon": "mdi-cancel",
          "color": "red lighten-3",
        };
      }
    }
  },

  data() {
    return {
      errorMessage: '',
      showCancelDialog: false,
      showConfirmDialog: false,
    }
  },

  methods: {
    closeConfirmDialog(arg) {
      if (arg != null && arg.scheduleId !== undefined) {
        this.$emit('onConfirm', {"scheduleId": this.schedule.id});
      }
      this.showConfirmDialog = false;
    },

    closeCancelDialog(arg) {
      if (arg != null && arg.scheduleId !== undefined) {
        this.$emit('onCancel', {"scheduleId": this.schedule.id});
      }
      this.showCancelDialog = false;
    },
  },
}
</script>

<style scoped>
.v-card__text {
  border: 1px solid #dadada !important;
  background-color: #FFFDE7;
}

.v-card__text:hover {
  border: 1px solid #FF8F00 !important;
  background-color: #f3efd4;
}
</style>