<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">Submit your schedule</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row class="justify-center" dense>
          <span class="text-h6 mb-6">{{ currentUser.username }}</span>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="auto" class="d-flex flex-row">
            <label class="mr-2">Start time:</label>
            <v-combobox
                class="combo-size"
                dense
                v-model="fromHour"
                :items="hours"
                label=""
            ></v-combobox>
            <v-combobox
                class="combo-size"
                dense
                v-model="fromMinute"
                :items="minutes"
                label=""
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="auto" class="d-flex flex-row">
            <label class="mr-2">End time:</label>
            <v-combobox
                class="combo-size"
                dense
                v-model="toHour"
                :items="hours"
                label=""
            ></v-combobox>
            <v-combobox
                class="combo-size"
                dense
                v-model="toMinute"
                :items="minutes"
                label=""
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row class="justify-center mt-2">
          <v-col cols="10">
            <v-textarea name="input-7-4" label="Note" v-model="comment" rows="4"
                        background-color="amber lighten-4" class="mt-n3"></v-textarea>
          </v-col>
        </v-row>

        <v-row class="justify-center mt-2" v-if="errorMessage">
          <v-col cols="auto">
            <v-alert text type="error">{{ errorMessage }}</v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-center mt-n6">
      <v-btn color="grey darken-1" class="mb-5 mt-2 px-8" outlined @click="closeDialog">Cancel</v-btn>
      <v-btn color="blue darken-1" class="mb-5 mt-2 px-12 ml-10" outlined @click="saveSchedule">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ScheduleService from "@/service/ScheduleService";
import {ScheduleInsert} from "@/model/schedule/ScheduleInsert";
import UserService from "@/service/UserService";

export default {
  name: "NewScheduleWorkerDialog",

  computed: {
    currentUser() {
      return UserService.getCurrentUser();
    }
  },

  data() {
    return {
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      minutes: [0, 10, 20, 30, 40, 50],
      fromHour: 8,
      fromMinute: 0,
      toHour: 16,
      toMinute: 0,
      comment: '',
      errorMessage: null
    }
  },

  methods: {
    closeDialog(arg) {
      this.$emit('onClose', arg);
    },

    async saveSchedule() {
      if (this.toHour < this.fromHour) {
        this.errorMessage = "End time must be greater than start time";
        return;
      }

      this.showOverlay = true;
      this.errorMessage = "";
      const dto = new ScheduleInsert(null, this.buildFromTime(), this.buildToTime(), this.comment);
      try {
        const newSchedule = await ScheduleService.insert(dto);
        this.closeDialog({"schedule": newSchedule});
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.showOverlay = false;
      }
    },

    buildFromTime() {
      let fromTime = '';
      if (this.fromHour < 10) {
        fromTime += '0';
      }
      fromTime += this.fromHour + ':';
      if (this.fromMinute < 10) {
        fromTime += '0';
      }
      fromTime += this.fromMinute;
      return fromTime;
    },

    buildToTime() {
      let toTime = '';
      if (this.toHour < 10) {
        toTime += '0';
      }
      toTime += this.toHour + ':';
      if (this.toMinute < 10) {
        toTime += '0';
      }
      toTime += this.toMinute;
      return toTime;
    }

  }

}
</script>

<style scoped>

.combo-size {
  max-width: 80px;
  margin-left: 10px;
  margin-top: -5px;
}

</style>